<template>
  <div>
    <div class="sm:my-6 my-3 flex items-center justify-between border-b pb-3 relative">
      <div>
        <h2 class="text-xl font-semibold">{{ role.name }} ({{ role.users.paginatorInfo.total }} thành viên)</h2>
      </div>

      <a href="#" uk-toggle="target: #addMemberModal" v-if="isAdminOfRole(role)"
         class="flex items-center justify-center h-10 w-10 z-10 rounded-full bg-green-600 hover:bg-green-700 text-white hover:text-white absolute right-0"
         data-tippy-placement="left" data-tippy="" data-original-title="Create New Page">
        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clip-rule="evenodd"></path>
        </svg>
      </a>
    </div>

    <div class="relative">
      <div class="uk-slider-container">
        <ul class="uk-child-width-1-5@m uk-child-width-1-5@s uk-child-width-1-2 uk-grid-small uk-grid"
            v-if="role.users && role.users.data && role.users.data.length">
          <li v-for="(user, index) in role.users.data" :key="'user' + index" class="mb-4">
            <div class="card">
              <router-link
                  :to="{name: 'UserDetail', params: {id: user.id}}">
                <img :alt="user.username"
                     :src="user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'"
                     class="h-44 object-cover rounded-t-md shadow-sm w-full">
              </router-link>
              <div class="p-3">
                <h4 class="text-base font-semibold mb-0.5 line-clamp-1">
                  <router-link
                      :to="{name: 'UserDetail', params: {id: user.id}}">
                    {{ user.username }}
                  </router-link>
                </h4>
                <p class="font-medium text-sm people-in-song line-clamp-1">
                  {{
                    user.userRolePivot && user.userRolePivot.custom_title ? user.userRolePivot.custom_title : getCustomTitle(user)
                  }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    
    <div class="mt-6">
      <comment :allow-comment="true" object-type="role"
               :comment-id="commentId"
               :show-more="false" :object-id="role.id" field="id" type="ID"/>
    </div>

    <div id="addMemberModal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Thành viên tham gia ({{ role.users.paginatorInfo.total }})</h3>
          <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2">
          <div class="col-span-2">
            <multiselect
                v-model="users"
                mode="tags"
                valueProp="id"
                label="title"
                trackBy="title"
                placeholder="Gõ để tìm kiếm"
                :closeOnSelect="true"
                :filterResults="false"
                :minChars="1"
                :resolveOnLoad="false"
                :delay="0"
                :searchable="true"
                :object="true"
                :options="searchUsers"
            >
              <template v-slot:option="{ option }">
                <img class="w-10 h-10 rounded-full object-cover mr-3"
                     :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  option.username
                }}
              </template>

              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag is-user">
                  <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                       class="w-6 h-6 rounded-full object-cover mr-3">
                  {{ option.username }}
                  <span
                      v-if="!disabled"
                      class="multiselect-tag-remove"
                      @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                </div>
              </template>
            </multiselect>
          </div>

          <div class="flex w-full border-t p-3 pl-0">
            <a href="javascript:void(0)" v-on:click="addUsersRole"
               class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
              Thêm thành viên</a>
          </div>
        </div>

        <div data-simplebar style="height: 250px">
          <div class="px-5">
            <div v-if="role.users && role.users.data && role.users.data.length">
              <div v-for="(user, index) in role.users.data" :key="'user' + index"
                   class="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
                <router-link :to="{name: 'UserDetail', params: {id: user.id}}"
                             class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative">
                  <img :src="user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'"
                       class="absolute w-full h-full inset-0 " alt="">
                </router-link>
                <div class="flex-1">
                  <router-link :to="{name: 'UserDetail', params: {id: user.id}}"
                               class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative">
                    <span class="text-base font-semibold capitalize">{{ user.username }}</span>
                  </router-link>
                </div>
                <a v-on:click="removeUserRole(user.id)" href="javascript:void(0)"
                   class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold">
                  Xóa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import ApiService from "../../core/services/api.service";
import {changePageTitle, isAdminOfRole} from "../../core/services/utils.service";
import Comment from "../comment/Comment";

export default {
  name: "RoleDetail",
  components: {Comment},
  data() {
    return {
      commentId: null,
      role: {
        id: 0,
        name: "",
        slug: " ",
        users: {
          data: [],
          paginatorInfo: {
            total: 0,
            currentPage: 1
          }
        }
      },
      users: []
    }
  },
  methods: {
    loadRole() {
      let query = `query($slug: String!) {
        roleBySlug(slug: $slug) {
          id
          name
          slug
          included_name
          group_type
          users(first: 100) {
            data {
              id
              username
              avatar {
                url
              }
              userRolePivot {
                role
                custom_title
              }
            }
            paginatorInfo {
              total
              currentPage
            }
          }
        }
      }`;

      ApiService.graphql(query, {slug: this.role.slug})
          .then(({data}) => {
            if (data.data && data.data.roleBySlug) {
              this.role = data.data.roleBySlug;
              changePageTitle(this.role.name);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    addUsersRole() {
      let query = `mutation($user_ids: [ID]!, $role_id: ID!) {
        addUsersRole(
          user_ids: $user_ids,
          role_id: $role_id
        )
      }`;

      let user_ids = [];
      this.users.map((item) => {
        user_ids.push(item.id);
      });

      ApiService.graphql(query, {
        user_ids: user_ids,
        role_id: this.role.id
      })
          .then(({data}) => {
            if (data.data && data.data.addUsersRole) {
              this.$toast.success("Thêm thành viên thành công");
              this.users = [];
              this.loadRole();
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    removeUserRole(user_id) {
      let query = `mutation($user_id: ID!, $role_id: ID!) {
        removeUserRole(
          user_id: $user_id,
          role_id: $role_id
        )
      }`;

      ApiService.graphql(query, {
        user_id: user_id,
        role_id: this.role.id
      })
          .then(({data}) => {
            if (data.data && data.data.removeUserRole) {
              this.$toast.success("Xóa thành viên thành công");
              this.loadRole();
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    isAdminOfRole(role) {
      return isAdminOfRole(role);
    },
    getCustomTitle(user) {
      let title = "";

      if (user.userRolePivot && user.userRolePivot.role) {
        if (user.userRolePivot.role === "admin") {
          title = "Trưởng";
        } else if (user.userRolePivot.role === "vice_admin") {
          title = "Phó";
        }
      }

      if (title) {
        title = title + " " + this.role.group_type;
      } else {
        title = "Thành viên";
      }

      return title;
    }
  },
  mounted() {
    this.commentId = this.$route.query.comment_id;
    this.role.slug = this.$route.params.slug;
    this.loadRole();
  },
  beforeRouteUpdate(to, from, next) {
    this.role.slug = this.$route.params.slug
    window.scrollTo(0, 0);
    next();
    this.loadRole();
  }
}
</script>
